import React from 'react';
import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { QueryClientProvider } from '@tanstack/react-query';

import '../i18n/index.js';
import queryClient from '../queryClient/index.jsx';
import QueryClientErrorHandler from '../../components/QueryClientErrorHandler/index.jsx';
import UseQueryParamsProvider from '../../components/UseQueryParamsProvider/index.jsx';
import HotkeysProvider from '../../components/HotkeysProvider/index.jsx';
import AnalyticsProvider from '../../components/AnalyticsProvider/index.jsx';
import SmartBrowserRouter from '../../components/SmartBrowserRouter/index.jsx';

export default function bootstrap({ App, ApplicationContextProvider, homepage }) {
  const root = createRoot(document.getElementById('root'));

  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <SmartBrowserRouter basename={homepage}>
          <AnalyticsProvider>
            <UseQueryParamsProvider>
              <ApplicationContextProvider>
                <HotkeysProvider>
                  <CssBaseline />
                  <App />
                </HotkeysProvider>
                <QueryClientErrorHandler />
              </ApplicationContextProvider>
            </UseQueryParamsProvider>
          </AnalyticsProvider>
        </SmartBrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>
  );

  return root;
}
