'use strict';

/**
 * @typedef {object} Feature
 * @property {string[]} privileges The list of privileges available for the feature. This list is sorted from the privilege that
 *  gives the least permissions to the one giving the most.
 * @property {boolean} [isModel=false] Whether this feature is materialized for a SQL model. This dictates the possibility to select individual
 *  items, and individual fields to further restrict the permissions.
 */

/**
 * The list of supported features.
 *
 * @type {{[name: string]: Feature}}
 */
module.exports = {
  user: {
    isModel: true,
    privileges: ['view', 'edit', 'impersonate']
  },
  brand: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  customer: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  contract: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  product: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  vendor: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  role: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  softwareType: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  software: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  tagType: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  tag: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  tagged: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  commcell: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  consent: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  netappController: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  vmwareVCenter: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  toolbox: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  toolboxAsset: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  netappCluster: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  netappStorageGrid: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  migration: {
    isModel: true,
    privileges: ['view', 'edit', 'revert']
  },
  snmpServer: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  userTag: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  oauthClient: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  oauthConsent: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  oauthProvider: {
    isModel: true,
    privileges: ['view', 'edit', 'login']
  },
  oauthTokenSet: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  email: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  linkedEmail: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  sentProduct: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  uploadedFile: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  toolboxSetting: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  userSetting: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  setting: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  eSerie: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  iDRAC: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  ciscoUCSPod: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  asset: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  brocadeSwitch: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  authorizedOperator: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  pureArray: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  pureOne: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  flashBlade: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  ciscoMDSSwitch: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  vigilanceFeed: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  servicenowInstance: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  elasticsearchCluster: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  fortinetSwitch: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  vmwareCloudDirector: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  vmwareNSXManager: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  cronJob: {
    isModel: true,
    privileges: ['view', 'edit', 'run']
  },
  vmwareCloudDirectorAvailability: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  webHost: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  tenant: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  tenantUrl: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  toolboxNetworkShare: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  billableProductOptions: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  mib: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  snmpMibServer: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  group: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  userGroup: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  groupGroup: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  netappActiveIQ: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  o365: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  backupAsAService: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  scalarLibrary: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  rubrikCluster: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  storreport: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  securityRole: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  securityPermission: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  securityUserRole: {
    isModel: true,
    privileges: ['view', 'edit']
  },
  billing: {
    privileges: ['view', 'edit']
  },
  assets: {
    privileges: ['view', 'bulk']
  },
  news: {
    privileges: ['view', 'edit']
  },
  reports: {
    privileges: ['view', 'create']
  },
  maintenance_warnings: {
    privileges: ['view', 'edit']
  },
  end_of_support: {
    privileges: ['view', 'edit', 'bulk']
  },
  default_dashboards: {
    privileges: ['view', 'edit']
  },
  official_templates: {
    privileges: ['view', 'edit']
  },
  themes: {
    privileges: ['view', 'edit']
  },
  fields_overrides: {
    privileges: ['view', 'edit']
  }
};
