'use strict';

/**
 * Replace diacritics of a string to their equivalent in normal letters.
 *
 * @param str {string} The string to normalize
 *
 * @returns {string} The normalized string
 */
module.exports = function normalizeDiacritics(str) {
  return str.normalize('NFKD')
    .replace(/\p{Diacritic}/gu, '')
    .replace(/œ/g, 'oe')
    .replace(/Œ/g, 'OE')
    .replace(/æ/g, 'ae')
    .replace(/Æ/g, 'AE');
};
