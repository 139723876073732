import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';

import useConfig from '../useConfig/index.js';

export default function useNeedsRefresh(initialRevision = null) {
  const { config } = useConfig(),
        [state, setState] = useState({ revision: initialRevision, changed: false });

  useQuery({
    queryKey: ['/api/revision'],
    select: useCallback((revision) => {
      setState((current) => {
        if (current.revision !== revision) {
          return { revision, changed: current.revision !== null };
        }

        return current;
      });
    }, []),
    refetchInterval: config.REVISION_CHECK_INTERVAL_SECONDS * 1000
  });

  return state.changed;
}
