import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import styles from './HotkeyKeySequence.module.scss';

export default function HotkeyKeySequence({ hotkey, className, Component = 'div' }) {
  const { t: __ } = useTranslation();

  function renderModifier(modifier, label) {
    if (!modifier) {
      return null;
    }

    return (
      <>
        <kbd>{label}</kbd>
        <span className={styles.plus}>+</span>
      </>
    );
  }

  return (
    <Component className={cx(styles.hotkey, className)} data-hotkey={hotkey.hint}>
      {renderModifier(hotkey.matchers.ctrlKey, 'Ctrl')}
      {renderModifier(hotkey.matchers.altKey, 'Alt')}
      {renderModifier(hotkey.matchers.shiftKey, 'Shift')}
      <kbd>{__(hotkey.hint)}</kbd>
    </Component>
  );
}
