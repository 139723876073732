import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Code from '../Code/index.jsx';
import ButtonBar from '../ButtonBar/index.jsx';
import useBooleanState from '../../services/useBooleanState/index.js';
import useApplicationContext from '../../services/useApplicationContext/index.js';
import RuthlessDialog from '../RuthlessDialog/index.jsx';

export default function QueryClientErrorHandler() {
  const { t: __ } = useTranslation(),
        queryClient = useQueryClient(),
        { showMessage } = useApplicationContext(),
        [error, setError] = useState(),
        [dialogOpen,, openDialog, closeDialog] = useBooleanState();

  function registerErrorHandlers() {
    function onError(err) {
      setError(err);
      showMessage(__('There was an error, please try again'), __('Details'), openDialog);
    }

    queryClient.getQueryCache().addOnErrorListener(onError);

    return function unregisterErrorHandlers() {
      queryClient.getQueryCache().removeOnErrorListener(onError);
    };
  }

  useEffect(registerErrorHandlers, [queryClient, openDialog, __, showMessage]);

  if (!error) {
    return null; // The Dialog will be rendered when the first error occurs
  }

  return (
    <RuthlessDialog open={dialogOpen} onClose={closeDialog} maxWidth={'md'}>
      <DialogTitle>{__('Details')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{error.message}</DialogContentText>
        <Code>{JSON.stringify(error.response?.data, null, 2)}</Code>
      </DialogContent>
      <DialogActions>
        <ButtonBar>
          <Button onClick={closeDialog}>{__('Close')}</Button>
        </ButtonBar>
      </DialogActions>
    </RuthlessDialog>
  );
}
