import Grid from '@mui/material/Grid';
import CheckCircle from '@mui/icons-material/CheckCircle';

import styles from './LogoBar.module.scss';

export default function LogoBar({ imageOne, imageTwo, altOne, altTwo }) {
  return (
    <div className={styles.logoBar}>
      <Grid container spacing={1} className={styles.images}>
        <Grid container size={{ xs: 12 }} sx={{ alignItems: 'center', justifyContent: 'center' }}>
          <Grid><img src={imageOne} data-testid="imageOne" alt={altOne} className={styles.imageOne} /></Grid>
          <Grid className={styles.spacer} />
          <Grid><CheckCircle className={styles.icon} data-testid="icon" /></Grid>
          <Grid className={styles.spacer} />
          <Grid><img src={imageTwo} data-testid="imageTwo" alt={altTwo} className={styles.imageTwo} /></Grid>
        </Grid>
      </Grid>
    </div>
  );
}
