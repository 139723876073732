'use strict';

const parseObject = require('./parseObject.cjs'),
      linkToItemTemplate = require('./linkToItemTemplate.cjs');

module.exports = function linkToObject(object) {
  const [techno, type, uuid] = parseObject(object);

  return linkToItemTemplate({ techno, type, field: 'metadata.uuid', name: uuid });
};
