import { BrowserRouter } from 'react-router';
import { useEffect } from 'react';

import useNeedsRefresh from '../../services/useNeedsRefresh/index.js';
import navigateTo from '../../services/navigateTo/index.js';

export default function SmartBrowserRouter({ basename, initialRevision, children }) {
  const needsRefresh = useNeedsRefresh(initialRevision);

  useEffect(() => {
    function smartHistoryChanger(original) {
      return function smartHistoryChangerFn(state, unused, url) {
        if (needsRefresh) {
          return navigateTo(url);
        }

        return original.call(window.history, state, unused, url);
      };
    }

    const originalPushState = window.history.pushState,
          originalReplaceState = window.history.replaceState;

    window.history.pushState = smartHistoryChanger(originalPushState);
    window.history.replaceState = smartHistoryChanger(originalReplaceState);

    return function restoreWindowOnUnmount() {
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, [needsRefresh]);

  return (
    <BrowserRouter basename={basename}>
      {children}
    </BrowserRouter>
  );
}
