import navigateTo from '../../navigateTo/index.js';

export default function logoutOn401(err) {
  if (!err.config.noLogoutOn401) {
    if (err.response.status === 401) {
      return navigateTo('/logout?reason=logout');
    }
  }

  throw err;
}
