import Dialog from '@mui/material/Dialog';

export default function RuthlessDialog({ onClose, ...props }) {
  function handleGlobalClose(event, reason) {
    if (onClose && reason !== 'backdropClick') {
      onClose(event, reason);
    }
  }

  return (
    <Dialog
      onClose={handleGlobalClose}
      {...props}
    />
  );
}
