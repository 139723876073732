'use strict';

module.exports = function asArray(items) {
  if (!items) {
    return [];
  }

  if (!Array.isArray(items)) {
    return [items];
  }

  return items;
};
