const ALL = '*';

/**
 * Translates a field.
 * This function contains some logic shared between the backend and the frontend, but sadly, as i18n libraries are not
 * the same between the two modules, it also accepts an actual translation function.
 *
 * @param techno {string} The field's techno
 * @param type {string} The field's type
 * @param name {string} The field's name
 * @param i18n {{t: function(key: string, options?: { count?: number, defaultValue: string })}} The actual translation function.
 *  The function will receive the count option as a hint to translate singular of plural forms. The function is expected to return
 *  the provided defaultValue option as a fallback.
 * @param [options] {object} Options used to tune the translation
 * @param [options.count=1] {number} The actual count, when known. Used to return the correct singular or plural form
 * @param [options.defaultValue=''] {string} A default value used when the field cannot be translated. Might be used for custom metrics
 *
 * @returns {string} The translated field name
 */
module.exports = function translateField(techno, type, name, i18n, { count = 1, defaultValue = '' } = {}) {
  const options = { defaultValue: '', isFieldTranslation: true };

  switch (name) {
    case 'metadata.object':
      if (type === ALL) {
        return i18n.t(name, options); // Might happen because "object" is a common field, so we might not always have the actual type
      }

      return i18n.t(type, { count, ...options }) || i18n.t(name, options); // Translate the type as the "object", in its singular form
    default:
      return i18n.t(`${techno}.${type}.${name}.override`, options) || i18n.t(`${techno}.${type}.${name}`, options) || i18n.t(`${techno}.${name}`, options) || i18n.t(name, options) || defaultValue;
  }
};
