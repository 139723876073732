import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useQueryParam } from 'use-query-params';

import navigateTo from '@stordata/cmdb-common/src/services/navigateTo/index.js';
import backend from '@stordata/cmdb-common/src/services/backend/index.js';
import PasswordCriteria from '@stordata/cmdb-common/src/components/PasswordCriteria/index.jsx';
import ButtonBar from '@stordata/cmdb-common/src/components/ButtonBar/index.jsx';

import useReturnTo from '../../services/useReturnTo/index.js';
import useJWT from '../../services/useJWT/index.js';

import styles from './PasswordResetConfirmForm.module.scss';

export default function PasswordResetConfirmForm() {
  const [jwt] = useJWT(),
        [returnTo] = useReturnTo(),
        [force] = useQueryParam('force'),
        { t: __ } = useTranslation(),
        { register, handleSubmit, formState: { isSubmitting } } = useForm(),
        [reset, setReset] = useState();

  function resetPassword(data) {
    return backend.post('/reset', data, { noLogoutOn401: true })
      .then(() => navigateTo(returnTo))
      .catch(err => setReset(err.response.data));
  }

  function showAlertMessage() {
    if (reset) {
      return (<Alert severity="error">{reset.error || __('There was an error, please try again')}</Alert>);
    }

    if (force) {
      return (<Alert severity="warning">{__('Stordata asks you to change your password')}</Alert>);
    }
  }

  return (
    <Card className={styles.form}>
      {showAlertMessage()}
      <form onSubmit={handleSubmit(resetPassword)}>
        <CardContent>
          <div className={styles.title}>
            <Typography variant="h5">{__('Change your password')}</Typography>
          </div>
          <Grid container spacing={1}>
            <Grid size={{ xs: 12 }}>
              <Tooltip title={<PasswordCriteria />} placement={'right'} arrow>
                <TextField
                  type="password"
                  label={__('New password')}
                  fullWidth
                  required
                  slotProps={{
                    htmlInput: register('password')
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Tooltip title={<PasswordCriteria />} placement={'right'} arrow>
                <TextField
                  type="password"
                  label={__('Confirm password')}
                  fullWidth
                  required
                  slotProps={{
                    htmlInput: register('confirmPassword')
                  }}
                />
              </Tooltip>
            </Grid>
            <input {...register('jwt')} type="hidden" value={jwt} />
          </Grid>
        </CardContent>
        <CardActions>
          <ButtonBar>
            <Button data-testid="submit" type="submit" disabled={isSubmitting}>{__('Confirm changes')}</Button>
          </ButtonBar>
        </CardActions>
      </form>
    </Card>
  );
}
