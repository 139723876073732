import { useTranslation } from 'react-i18next';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { useContext, useState } from 'react';

import { stringComparator } from '@stordata/cmdb-shared';

import useHotkey from '../../../services/useHotkey/index.js';
import Hotkey from '../../Hotkey/index.jsx';
import ButtonBar from '../../ButtonBar/index.jsx';
import useBooleanState from '../../../services/useBooleanState/index.js';
import CommandPalette from '../CommandPalette/index.jsx';
import HotkeysContext from '../HotkeysContext.jsx';
import RuthlessDialog from '../../RuthlessDialog/index.jsx';

export default function HotkeyRecap({ registry }) {
  const { t: __ } = useTranslation(),
        [open,,, closeDialog, toggleDialog] = useBooleanState(),
        { trigger } = useContext(HotkeysContext),
        [requestedCommand, setRequestedCommand] = useState(),
        hotkeys = groupHotkeysByDescription().sort(byDescription);

  function groupHotkeysByDescription() {
    function groupByDescription(acc, { hotkey, description: [id, description] }) {
      if (!(id in acc)) {
        acc[id] = [__(description)];
      }

      acc[id].push(hotkey);

      return acc;
    }

    return Object.entries(registry.reduce(groupByDescription, {}));
  }

  function byDescription([, [descriptionA]], [, [descriptionB]]) {
    return stringComparator(descriptionA, descriptionB);
  }

  function onCommand(command) {
    closeDialog();
    setRequestedCommand(command);
  }

  function onEntered() {
    setRequestedCommand(null);
  }

  function onExited() {
    if (requestedCommand) {
      trigger([requestedCommand]);
    }
  }

  useHotkey(Hotkey.CTRL_K, toggleDialog, Hotkey.DISPLAY_HOTKEYS);
  useHotkey(Hotkey.QUESTION_MARK, toggleDialog, Hotkey.DISPLAY_HOTKEYS);

  return (
    <RuthlessDialog open={open} onClose={closeDialog} maxWidth={'md'} fullWidth slotProps={{ transition: { onEntered, onExited } }}>
      <DialogTitle>{__('Hotkeys')}</DialogTitle>
      <DialogContent>
        <CommandPalette hotkeys={hotkeys} onCommand={onCommand} />
        <Table size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell>{__('Name')}</TableCell>
              <TableCell>{__('Hotkey')}</TableCell>
              <TableCell>{__('Secondary hotkey')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hotkeys.map(([id, [description, primary, secondary]]) => (
              <TableRow key={id}>
                <TableCell>{__(description)}</TableCell>
                <TableCell>{primary.renderKeySequence()}</TableCell>
                <TableCell>{secondary?.renderKeySequence()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <ButtonBar>
          <Button onClick={closeDialog}>{__('Close')}</Button>
        </ButtonBar>
      </DialogActions>
    </RuthlessDialog>
  );
}
