import { useSuspenseQuery } from '@tanstack/react-query';

export default function useConfig() {
  const { data: config } = useSuspenseQuery({ queryKey: ['/api/users/me/config'] });

  return {
    config: {
      PLUGINS: config.PLUGINS,
      SERVICENOW_URL: config.SERVICENOW_URL,
      MATOMO_URL: config.MATOMO_URL,
      MATOMO_SITE_ID: config.MATOMO_SITE_ID,
      UPLOADED_IMAGE_MAX_SIZE_BYTES: config.UPLOADED_IMAGE_MAX_SIZE_BYTES,
      CMDB_URL: config.CMDB_URL,
      REVISION_CHECK_INTERVAL_SECONDS: config.REVISION_CHECK_INTERVAL_SECONDS
    }
  };
}
